import React from "react"
import { connect } from "react-redux"
import { chakra, Container, Heading } from "@chakra-ui/react"

const PageStore = ({ game }) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Container maxW="1200px" minH="640px">
      <Heading as="h1" textStyle="h1">
        Store
      </Heading>
      <chakra.pre fontSize="xs">
        <code>{JSON.stringify({ game }, undefined, 2)}</code>
      </chakra.pre>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(PageStore)
