
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sunt exercitation in eu magna voluptate Lorem id amet anim consequat ullamco.
Mollit laborum nulla in nostrud non labore adipisicing id eu culpa magna minim
exercitation aute. Consequat cupidatat est exercitation aute do irure mollit
enim non.`}</p>
    <p>{`Nostrud eu proident consectetur occaecat culpa eiusmod adipisicing eu excepteur
consequat laborum laborum. Eu laboris nostrud voluptate consectetur eiusmod
deserunt consectetur exercitation. Tempor est consectetur id laborum eu irure
laboris excepteur velit ad voluptate in.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;