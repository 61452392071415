import React, { useRef, useEffect } from "react"
import { connect } from "react-redux"
import {
  chakra,
  Container,
  Flex,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react"
import Countdown, { zeroPad } from "react-countdown"
import { BsPause, BsPlay } from "react-icons/bs"

import { SETUP } from "../../const/setup"

import {
  isOnPauseUpdate,
  isTimeUpUpdate,
  isOpenTimeUpFalse,
  durationSuccessUpdate,
} from "../../redux/actions/game"

const Timer = ({
  expiryTimestamp,
  isTimeUp,
  isOpenTimeUp,
  isSuccess,
  language,
  dispatch,
}) => {
  //=================================================================
  const cancelRef = useRef()
  const countdownRef = useRef()

  const { timeUpHead, timeUpBody } = SETUP

  useEffect(() => {
    // if success Timer is paused
    if (isSuccess) {
      countdownRef.current.api.pause()
    }
  }, [isSuccess])

  //-----------------------------------------------------------------
  const onClose = () => {
    dispatch(isOpenTimeUpFalse())
  }

  //-----------------------------------------------------------------
  const onComplete = () => {
    // setTimeout(() => dispatch(isTimeUpUpdate()), 0)
    dispatch(isTimeUpUpdate())
  }

  const onStart = () => {
    const payload = { isOnPause: false }
    dispatch(isOnPauseUpdate(payload))
  }

  const onPause = (timeDelta) => {
    // timeDelta: {
    //   completed: false,
    //   total: 84000, // in milliseconds
    //   days: 0,
    //   hours: 0,
    //   minutes: 1,
    //   seconds: 24,
    //   milliseconds: 0,
    // }

    // if pause is result of useEffect
    // durationSuccessUpdate is dispatched
    if (isSuccess) {
      const payload = { timeDelta }
      dispatch(durationSuccessUpdate(payload))
    } else {
      const payload = { isOnPause: true }
      dispatch(isOnPauseUpdate(payload))
    }
  }

  const renderer = ({ api, hours, minutes, seconds }) => {
    return (
      <Flex align="center">
        <Flex
          w="200px"
          fontSize="36px"
          fontFamily="Inconsolata"
          color={isTimeUp ? "red.500" : "inherit"}
        >
          <Text fontSize="inherit">
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </Text>
        </Flex>

        <chakra.button
          onClick={api.pause}
          position="relative"
          top="-4px"
          outline="none"
          ml={4}
        >
          <BsPause size="24px" />
        </chakra.button>
        <chakra.button
          onClick={api.start}
          ml={4}
          position="relative"
          top="-4px"
          outline="none"
        >
          <BsPlay size="24px" />
        </chakra.button>
      </Flex>
    )
  }

  //=================================================================
  return (
    <>
      <Container maxW="1200px" py={2}>
        <Countdown
          ref={countdownRef}
          date={expiryTimestamp}
          renderer={renderer}
          overtime={true}
          onComplete={onComplete}
          onStart={onStart}
          onPause={onPause}
        />
      </Container>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpenTimeUp}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{timeUpHead[language]}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{timeUpBody[language]}</AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    expiryTimestamp: state.game.expiryTimestamp,
    isTimeUp: state.game.isTimeUp,
    isOpenTimeUp: state.game.isOpenTimeUp,
    isSuccess: state.game.isSuccess,
    language: state.game.language,
  }
}

export default connect(mapStateToProps)(Timer)
