import { createAction } from "@reduxjs/toolkit"

export const codeUpdate = createAction("CODE_UPDATE")
export const durationSuccessUpdate = createAction("DURATION_SUCCESS_UPDATE")
export const hintsUpdate = createAction("HINT_USED_UPDATE")
export const isDebugOnUpdate = createAction("IS_DEBUG_ON_UPDATE")
export const isOnPauseUpdate = createAction("IS_ON_PAUSE_UPDATE")
export const isOpenFeedbackFalse = createAction("IS_OPEN_FEEDBACK_FALSE")
export const isOpenTimeUpFalse = createAction("IS_OPEN_TIME_UP_FALSE")
export const isTimeUpUpdate = createAction("IS_TIME_UP_UPDATE")
export const isToBeLoginShownUpdate = createAction("IS_PASS_CODE_SHOWN_UPDATE")
export const toBeLoginNameUpdate = createAction("SET_LOGIN_CODE_UPDATE")
export const languageUpdate = createAction("LANGUAGE_UPDATE")
