/**
 * v1.0.0
 *
 * Transforms a duration object as defined by date-fns
 * into seconds.
 * https://date-fns.org/v2.21.1/docs/Duration
 * @param {object} duration
 * @returns {number} seconds
 */
export function durationToSeconds(duration) {
  let seconds = 0

  if (duration.total) {
    // duration.total is in milliseconds
    // round up to next full second
    seconds = Math.ceil(duration.total / 1000)
  } else {
    duration.years && (seconds += duration.years * 31556952)

    duration.months && (seconds += duration.months * 2678400)

    duration.weeks && (seconds += duration.weeks * 604800)

    duration.days && (seconds += duration.days * 86400)

    duration.hours && (seconds += duration.hours * 3600)

    duration.minutes && (seconds += duration.minutes * 60)

    duration.seconds && (seconds += duration.seconds)
  }

  return seconds
}

/**
 * v1.0.0
 *
 * Transforms seconds into a duration object.
 * https://date-fns.org/v2.21.1/docs/Duration
 * @param {number} seconds
 * @returns {object} duration
 */
export function secondsToDuration(seconds) {
  let duration = {}

  duration.total = seconds * 1000

  duration.years = Math.floor(seconds / 31556952)
  seconds = seconds % 31556952

  duration.months = Math.floor(seconds / 2678400)
  seconds = seconds % 2678400

  duration.weeks = Math.floor(seconds / 604800)
  seconds = seconds % 604800

  duration.days = Math.floor(seconds / 86400)
  seconds = seconds % 86400

  duration.hours = Math.floor(seconds / 3600)
  seconds = seconds % 3600

  duration.minutes = Math.floor(seconds / 60)
  duration.seconds = seconds % 60

  return duration
}
