
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i05 from "../../img/1280/16x9/05.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i05} mdxType="Image" />
    <h1>{`Willkommen in der Burgküche.`}</h1>
    <p>{`Heute bleibt die Küche kalt. Stattdessen solltet ihr schnellstens im Krug hinten
rechts nachschauen. Dort befindet sich das nächste Rätsel.`}</p>
    <Text mb={2} mdxType="Text">
  Ihr seht also im Krug nach und findet einen Zettel mit folgendem Link:{" "}
  <Link href="https://learningapps.org/display?v=pya7hx9nv21" isExternal color="purple.300" _hover={{
        color: "purple.600"
      }} mdxType="Link">
    https://learningapps.org/display?v=pya7hx9nv21
  </Link>
    </Text>
    <p>{`Löst bitte das Rätsel zum Thema `}<em parentName="p">{`Fragetechniken`}</em>{` und findet das Kennwort für den
nächsten Raum.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;