/* eslint-disable import/no-webpack-loader-syntax */
import React from "react"
import { Container } from "@chakra-ui/react"
import { MDXProvider } from "@mdx-js/react"
import components from "../../theme/components"
import Content from "!babel-loader!@mdx-js/loader!./Content.mdx"

const PageDataProtection = () => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Container maxW="1200px" minH="640px">
      <MDXProvider components={components}>
        <Content />
      </MDXProvider>
    </Container>
  )
}

export default PageDataProtection
