import { EXPIRY_IN_DAYS } from "../../const/setup"
import { nanoId1 } from "./nanoid"

/**
 * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 * Generates a hash from a string
 * @param {string} str
 * @return {string} hash
 */
export const shortHashGen = (s) => {
  for (var i = 0, h = 9; i < s.length; )
    h = Math.imul(h ^ s.charCodeAt(i++), 9 ** 9)
  const result = h ^ (h >>> 9)
  return Math.abs(result).toString(16).padStart(8, 0)
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 *
 * @param {string} str
 * @returns {string} str with a pint after 4 chars
 */
export const addPoint = (str) => str.slice(0, 4) + "." + str.slice(4, 8)

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates a key by hashing a string and by
 * add
 * @param {*} s
 * @returns {string} key
 */
export const keyGen = (s, expiryInDays = EXPIRY_IN_DAYS + 1) => {
  const expiryDaySprinkled = expireDaysSprinkledGen(expiryInDays)
  const hash = shortHashGen(s)
  const key = hash + expiryDaySprinkled
  return key
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Sprinkles a string with random chars at given indexes.
 * @param {string} str
 * @param {array} indexes
 * @returns {string} strNew
 */
export const sprinkle = (str, indexes = [1, 4]) => {
  let strArr = [...str]
  strArr.splice(indexes[0], 0, nanoId1())
  strArr.splice(indexes[1] + 1, 0, nanoId1())
  let strNew = strArr.join("")
  return strNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Removes sprinkles from a string at given indexes.
 * @param {string} str
 * @param {array} indexes
 * @returns {string} strNew
 */
export const unSprinkle = (str, indexes = [1, 4]) => {
  let strArr = [...str]
  strArr.splice(indexes[0], 1)
  strArr.splice(indexes[1], 1)
  let strNew = strArr.join("")
  return strNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates an expiry day
 * @param {number} expiryInDays
 * @returns {string} expiryDaySprinkled
 */
export const expireDaysSprinkledGen = (expiryInDays) => {
  const milliSecondsPerDay = 86400000

  const today = Math.floor(+Date.now() / milliSecondsPerDay)
  const expiryDay = (today + expiryInDays).toString()
  const expiryDaySprinkled = sprinkle(expiryDay)

  return expiryDaySprinkled
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates an expiry day
 * @param {number} expiryInDays
 * @returns {string} expiryDaySprinkled
 */
export const expiryTimestampRecover = (expiryDaySprinkled) => {
  const milliSecondsPerDay = 86400000
  const expiryDay = parseInt(unSprinkle(expiryDaySprinkled))
  const expiryTimestamp = expiryDay * milliSecondsPerDay
  return expiryTimestamp
}
