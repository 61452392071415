const Link = {
  baseStyle: {
    transition: "all 0.15s ease-out",
    cursor: "pointer",
    textDecoration: "none",
    outline: "none",
    color: "inherit",
    _hover: { textDecoration: "none", color: "pink.100", outline: "none" },
    _focus: { outline: "none" },
  },
}

export default Link
