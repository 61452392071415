
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i06 from "../../img/1280/16x9/06.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const Link = makeShortcode("Link");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i06} mdxType="Image" />
    <h1>{`Das ist mal ein Badezimmer!`}</h1>
    <p>{`Zeit für ein Bad bleibt euch natürlich auch hier nicht. Findet heraus, auf
welchen Namen der Kater im Bild hört. Löst dafür folgendes Rätsel:`}</p>
    <Link href="https://learningapps.org/display?v=p4jw74ci321" isExternal display="block" mb={2} color="purple.300" _hover={{
      color: "purple.600"
    }} mdxType="Link">
  https://learningapps.org/display?v=p4jw74ci321
    </Link>
    <p>{`Der Name des Katers ist das Kennwort für den nächsten Raum.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;