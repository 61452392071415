import React from "react"
import * as ChakraComponents from "@chakra-ui/react"
import {
  chakra,
  Box,
  OrderedList,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"

const MDXComponents = {
  h1: (props) => <Box as="h1" textStyle="h1" {...props} />,
  h2: (props) => <Box as="h2" textStyle="h2" {...props} />,
  h3: (props) => <Box as="h3" textStyle="h3" {...props} />,
  h4: (props) => <Box as="h4" textStyle="h4" {...props} />,
  h5: (props) => <Box as="h5" textStyle="h5" {...props} />,
  h6: (props) => <Box as="h6" textStyle="h6" {...props} />,
  hr: (props) => <chakra.hr {...props} />,
  a: (props) => <chakra.a {...props} />,
  p: (props) => <chakra.p mb={2} {...props} />,
  ul: (props) => <UnorderedList mb={2} {...props} />,
  ol: (props) => <OrderedList mb={2} {...props} />,
  li: (props) => <ListItem ml={1} {...props} />,
}

const components = { ...ChakraComponents, ...MDXComponents }

export default components
