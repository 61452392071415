import React from "react"
import { connect } from "react-redux"
import { Link as LinkRouter } from "react-router-dom"
import { Container, Flex, Link } from "@chakra-ui/react"
import { NUMBER_OF_RIDDLES } from "../../const/setup"

const RiddlePages = ({ game, dispatch }) => {
  //=================================================================
  const riddlePageURLs = game?.riddlePageURLs
  const riddleHeadings = game?.riddleHeadings
  const { solved } = game
  //-----------------------------------------------------------------

  const renderLinks = () => {
    const renderedLinks = Array(NUMBER_OF_RIDDLES + 1)
      .fill(null)
      .map((value, index) => {
        if (solved[index]) {
          if (index === NUMBER_OF_RIDDLES) {
            return (
              <Link
                key={index}
                as={LinkRouter}
                to={riddlePageURLs[0]}
                mr={4}
                _hover={{ color: "purple.800" }}
                fontSize="13px"
              >
                {riddleHeadings[0]}
              </Link>
            )
          } else
            return (
              <Link
                key={index}
                as={LinkRouter}
                to={riddlePageURLs[index + 1]}
                mr={4}
                _hover={{ color: "purple.800" }}
                fontSize="13px"
              >
                {riddleHeadings[index + 1]}
              </Link>
            )
        }
        return null
      })
    return renderedLinks
  }

  //=================================================================
  return (
    <Container maxW="100%" bg="#B1C0B9">
      <Container maxW="1200px" py={2}>
        <Flex wrap="wrap">
          <Link
            as={LinkRouter}
            to="/"
            fontSize="13px"
            mr={4}
            _hover={{ color: "purple.800" }}
          >
            Start
          </Link>
          {renderLinks()}
        </Flex>
      </Container>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(RiddlePages)
