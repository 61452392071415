// import { customAlphabet } from "nanoid"
import { customAlphabet, customRandom } from "nanoid"
import seedrandom from "seedrandom"
import {
  CUSTOM_ALPHABET,
  CUSTOM_ALPHABET_NUMBERS,
  LENGTH_URL,
} from "../../const/setup"

// no: '0', l', 'o'
// always start with a letter
export const nanoId1 = customAlphabet(CUSTOM_ALPHABET, 1)
export const nanoId2 = customAlphabet(CUSTOM_ALPHABET_NUMBERS, LENGTH_URL)

export const nanoId = () => nanoId1() + nanoId2()

export const nanoURLs = ({ seed = nanoId(), nb, lengthURL = LENGTH_URL }) => {
  const rng = seedrandom(seed)
  let ids = Array(nb)
    .fill(null)
    .map(() =>
      customRandom(CUSTOM_ALPHABET, lengthURL, (size) => {
        return new Uint8Array(size).map(() => 256 * rng())
      })()
    )

  ids = ids.map((el) => "/" + el)

  return ids
}
