import { extendTheme } from "@chakra-ui/react"

import breakpoints from "./breakpoints"
import colors from "./colors"
import fonts from "./fonts"
import styles from "./styles"
import textStyles from "./textStyles"
import Container from "./Container"
import Divider from "./Divider"
import Heading from "./Heading"
import Link from "./Link"

const theme = extendTheme({
  breakpoints,
  colors,
  fonts,
  textStyles,
  styles,
  components: { Container, Divider, Heading, Link },
})

export default theme
