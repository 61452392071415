import React, { useRef } from "react"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { chakra, Container, Flex, Box, Text, Link } from "@chakra-ui/react"
import { Link as LinkRouter } from "react-router-dom"
import { VERSION_APP } from "../../const/versions"
import { META_URL_SITE } from "../../const/urlCRA"

import {
  toBeLoginNameUpdate,
  isToBeLoginShownUpdate,
  isDebugOnUpdate,
} from "../../redux/actions/game"

import Bowser from "bowser"
import preval from "preval.macro"

const browser = Bowser.parse(window.navigator.userAgent)

let date = preval`
const vHoursDiff = process.env.NODE_ENV === "production" ? 1 : 0
let newDate = new Date()
newDate.setTime(newDate.getTime() + (vHoursDiff*60*60*1000));
const dateStr = newDate.getFullYear() + "-" +
    ("0"+ (newDate.getMonth()+1)).slice(-2) + "-" +
    ("0" + newDate.getDate()).slice(-2)  + "|" +
    ("0" + newDate.getHours()).slice(-2) + ":" +
    ("0" + newDate.getMinutes()).slice(-2);
module.exports=dateStr`

const PageFooter = ({ game, dispatch }) => {
  // ==========================================================================

  const { isToBeLoginShown, toBeLoginCode, isDebugOn } = game

  const okRef = useRef()
  const { register, handleSubmit } = useForm()

  const dispatchIsToBeLoginShown = () => {
    dispatch(isToBeLoginShownUpdate())
  }

  const dispatchIsDebugOnUpdate = () => {
    dispatch(isDebugOnUpdate())
  }

  const onSubmit = (data) => {
    okRef.current.focus()
    const payload = { toBeLoginName: data.toBeLoginName }
    dispatch(toBeLoginNameUpdate(payload))
  }

  // ==========================================================================
  return (
    <Container w="100%" color="white" bg="grey.700" px={4} py={8} mt={16}>
      <Flex justify="space-between" wrap="wrap">
        <Flex direction="column">
          <Text>© Susanne Schoenborn: Inhalt & Rätsel</Text>
          <Text>© Prof. Dr. Ulrich Anders: Anpassung & Technik</Text>
          <Text>© Lilli Anders: Idee & Technik</Text>

          <Link href={META_URL_SITE + "/"} isExternal>
            {META_URL_SITE}
          </Link>
          <Link
            href="https://creativecommons.org/licenses/by-nd/4.0/"
            isExternal
          >
            License: CC BY-NC-ND 4.0
          </Link>
          {isToBeLoginShown ? (
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="Off">
              <chakra.input
                {...register("toBeLoginName", {
                  required: true,
                  maxLength: 80,
                })}
                placeholder="Login Name"
                type="text"
                color="purple.100"
                bg="transparent"
              />
              <chakra.button
                maxW="0"
                type="submit"
                ref={okRef}
                outline="none"
              ></chakra.button>
            </form>
          ) : (
            <Box h={"24px"}></Box>
          )}
        </Flex>

        <Box>
          <Text>
            Vers
            <chakra.button type="button" onClick={dispatchIsToBeLoginShown}>
              i
            </chakra.button>
            on: {VERSION_APP}
          </Text>
          <Text>Last change: {date}</Text>
          <Text>
            Browser: {browser.browser.name} {browser.browser.version}
          </Text>
          <Flex>
            <Link as={LinkRouter} to="/imprint">
              Imprint
            </Link>
            <Text mx={2}>
              <chakra.button onClick={dispatchIsDebugOnUpdate}>
                {isDebugOn ? (
                  <Text w={1} textAlign="center">
                    •
                  </Text>
                ) : (
                  <Text w={1} textAlign="center">
                    ·
                  </Text>
                )}
              </chakra.button>
            </Text>
            <Link as={LinkRouter} to="/data-protection">
              Data Protection
            </Link>
          </Flex>
          {isToBeLoginShown && (
            <Text h={"24px"} color="purple.100">
              {toBeLoginCode}
            </Text>
          )}
        </Box>
      </Flex>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(PageFooter)
