import React from "react"
import { BrowserRouter } from "react-router-dom"
import { Provider as StoreProvider } from "react-redux"
import { HelmetProvider } from "react-helmet-async"
import { ChakraProvider } from "@chakra-ui/react"
import { IconContext } from "react-icons"

import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"

import "@fontsource/inconsolata/400.css"
import "@fontsource/inconsolata/500.css"
import "@fontsource/inconsolata/600.css"
import "@fontsource/inconsolata/700.css"

import App from "../App"
import ScrollToTop from "../ScrollToTop"
import { store } from "../../redux/store"

import theme from "../../theme"
import "./style/App.css"
import "simplebar/dist/simplebar.min.css"

const AppWrapper = () => {
  // ====================================================================
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ChakraProvider resetCSS={true} theme={theme}>
          <IconContext.Provider value={{ className: "react-icons" }}>
            <StoreProvider store={store}>
              <ScrollToTop />
              <App />
            </StoreProvider>
          </IconContext.Provider>
        </ChakraProvider>
      </HelmetProvider>
    </BrowserRouter>
  )
}

export default AppWrapper
