import React from "react"
import { connect } from "react-redux"
import { Routes, Route } from "react-router-dom"
import { Container } from "@chakra-ui/react"

import PageHeader from "../PageHeader"
import Timer from "../Timer"
import RiddlePages from "../RiddlePages"
import PageFooter from "../PageFooter"
import PageStart from "../../pages/PageStart"
import PageStore from "../../pages/PageStore"
import PageAbout from "../../pages/PageAbout"
import PageLicense from "../../pages/PageLicense"
import PageImprint from "../../pages/PageImprint"
import PageDataProtection from "../../pages/PageDataProtection"
import PageRiddle01 from "../../pages/PageRiddle01"
import PageRiddle02 from "../../pages/PageRiddle02"
import PageRiddle03 from "../../pages/PageRiddle03"
import PageRiddle04 from "../../pages/PageRiddle04"
import PageRiddle05 from "../../pages/PageRiddle05"
import PageRiddle06 from "../../pages/PageRiddle06"
import PageRiddle07 from "../../pages/PageRiddle07"
import PageRiddle08 from "../../pages/PageRiddle08"
import PageRiddle09 from "../../pages/PageRiddle09"
import PageSuccess from "../../pages/PageSuccess"

const App = ({ game, dispatch }) => {
  //======================================================================
  const isInitialized = game?.isInitialized
  const riddlePageURLs = game?.riddlePageURLs
  const expiryTimestamp = game?.expiryTimestamp

  //======================================================================
  return (
    <>
      {isInitialized && (
        <Container maxW="100%" m={0}>
          <PageHeader game={game} />
          {expiryTimestamp && <Timer />}
          <RiddlePages />
          <Routes>
            <Route path="/" element={<PageStart />} />
            <Route path="/store" element={<PageStore />} />
            <Route path="/license" element={<PageLicense />} />
            <Route path="/about" element={<PageAbout />} />
            <Route path="/imprint" element={<PageImprint />} />
            <Route path="/data-protection" element={<PageDataProtection />} />

            {riddlePageURLs?.[0] && (
              <Route path={riddlePageURLs[0]} element={<PageSuccess />} />
            )}

            {riddlePageURLs?.[1] && (
              <Route path={riddlePageURLs[1]} element={<PageRiddle01 />} />
            )}

            {riddlePageURLs?.[2] && (
              <Route path={riddlePageURLs[2]} element={<PageRiddle02 />} />
            )}

            {riddlePageURLs?.[3] && (
              <Route path={riddlePageURLs[3]} element={<PageRiddle03 />} />
            )}

            {riddlePageURLs?.[4] && (
              <Route path={riddlePageURLs[4]} element={<PageRiddle04 />} />
            )}

            {riddlePageURLs?.[5] && (
              <Route path={riddlePageURLs[5]} element={<PageRiddle05 />} />
            )}

            {riddlePageURLs?.[6] && (
              <Route path={riddlePageURLs[6]} element={<PageRiddle06 />} />
            )}

            {riddlePageURLs?.[7] && (
              <Route path={riddlePageURLs[7]} element={<PageRiddle07 />} />
            )}

            {riddlePageURLs?.[8] && (
              <Route path={riddlePageURLs[8]} element={<PageRiddle08 />} />
            )}

            {riddlePageURLs?.[9] && (
              <Route path={riddlePageURLs[9]} element={<PageRiddle09 />} />
            )}
          </Routes>
          <PageFooter />
        </Container>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(App)
