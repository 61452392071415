/* eslint-disable import/no-webpack-loader-syntax */
import React from "react"
import { connect } from "react-redux"
import { Container, Image } from "@chakra-ui/react"
import { MDXProvider } from "@mdx-js/react"
import components from "../../theme/components"
import ContentDe from "!babel-loader!@mdx-js/loader!./Content.de.mdx"
import ContentEn from "!babel-loader!@mdx-js/loader!./Content.en.mdx"
import iLicense from "../../img/iLicense/1280/iLicense.jpg"

const PageLicense = ({ game }) => {
  //=================================================================
  const { language } = game

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Container maxW="1200px" minH="640px">
      <Image src={iLicense} mt={8} />
      <MDXProvider components={components}>
        {language === "en" ? (
          <ContentEn />
        ) : language === "de" ? (
          <ContentDe />
        ) : null}
      </MDXProvider>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(PageLicense)
