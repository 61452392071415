import React from "react"
import { connect } from "react-redux"
import { Container, Heading, Text, Image } from "@chakra-ui/react"
import FormCode from "../../components/FormCode"
import { NUMBER_OF_RIDDLES } from "../../const/setup"
import formatDuration from "date-fns/formatDuration"
import i00 from "../../img/1280/16x9/00.jpg"
import { enUS as en, de } from "date-fns/locale"
import { SETUP } from "../../const/setup"

const PageStart = ({ game }) => {
  //=================================================================
  const { duration, hintPenaltyDuration, hasHints } = SETUP
  const { isResetForm, language } = game

  const locales = { en, de }

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Container maxW="1200px" minH="640px">
      <Image src={i00} mt={8} />

      {language === "en" ? (
        <>
          <Heading as="h1" textStyle="h1">
            Welcome to the CBS Escape Room Game
          </Heading>
          <Text mb={2}>
            The game starts immediately after you have entered your email and
            the correct licensing code. For that reason, please{" "}
            <strong>
              first read the instructions and the information below
            </strong>
            .
          </Text>
          <Text mb={2}>
            The idea of this game is built on the well-known concept of escape
            rooms. Each room is represented by its individual page on this
            website. In order to escape from a room, you need to solve a riddle
            to find a secret code. After entering the correct secret code the
            website will offer you a new page with a new riddle. This continues
            until you have finally succeeded to escape from all rooms and made
            it to the end of the game.
          </Text>
          <Text mb={2}>
            This game offers you <strong>{NUMBER_OF_RIDDLES} riddles</strong>.
            To solve these you will have{" "}
            <strong>
              {formatDuration(duration, { locale: locales[language] })}
            </strong>
            . The moment the game starts, a countdown timer will show up and
            keep track of your time.
          </Text>
          {hasHints && (
            <Text mb={2}>
              Only look at hints if you really need some help. Looking at{" "}
              <strong>
                a hint will cost you{" "}
                {formatDuration(hintPenaltyDuration, {
                  locale: locales[language],
                })}
              </strong>
              , which will be added to your total game time after completion.
            </Text>
          )}
          <Text mb={8}>Good luck and have fun!</Text>
        </>
      ) : language === "de" ? (
        <>
          <Heading as="h1" textStyle="h1">
            Willkommen auf Burg Fürchtenstein!
          </Heading>
          <Text mb={2}>
            Liebe Studierende, ich heiße euch Willkommen zu eurem "Digital
            Escape Room" als Abschluss unserer Vorlesung{" "}
            <em>Kommunikation und Gesprächsführung</em>. Was ist ein "Escape
            Room"? Escape Rooms erfreuen sich seit Jahren großer Beliebtheit. Es
            gilt, im Team verschiedene Rätsel zu lösen, um gemeinsam aus dem
            Escape Room zu finden oder einen Schatz zu bergen. "Digital Escape
            Rooms" sind wiederum internetbasierte Rätselräume.
          </Text>
          <Text mb={2}>
            Seid ihr also bereit, auf Schatzsuche zu gehen? Dann begebt euch
            jetzt auf die Jagd nach dem gesuchten 3-stelligen Code, des Rätsels
            Lösung.
          </Text>
          <Text mb={2}>
            Das Spiel startet sofort, nachdem ihr die E-Mail und den korrekten
            Lizenzcode eingegeben habt. Bitte lest aus diesem Grund{" "}
            <strong>
              erst die nachfolgenden Anweisungen und Informationen
            </strong>
            .
          </Text>
          <Text mb={2}>
            Jeder Raum wird durch eine eigene Seite auf dieser Website
            repräsentiert. Um aus einem Raum zu entkommen, muss man allerdings
            ein Rätsel lösen, indem man einen Geheimcode findet. Nach der
            Eingabe des richtigen Geheimcodes wird auf der Website eine neue
            Seite mit einem neuen Rätsel freigeschaltet. Das geht so lange so
            weiter, bis man aus dem letzten Raum entkommen ist. Dann ist das
            Spiel beendet. Sollte ein Code Buchstaben enthalten, ist es egal, ob
            ihr Klein- oder Großbuchstaben eingebt.
          </Text>
          <Text mb={2}>
            Dieses Spiel hat <strong>{NUMBER_OF_RIDDLES} Rätsel</strong>. Für
            das Spiel habt ihr{" "}
            <strong>
              {formatDuration(duration, { locale: locales[language] })}
            </strong>
            . Nachdem das Spiel gestartet wurde, erscheint ein Countdown-Zähler
            und misst die Zeit.
          </Text>
          {hasHints && (
            <Text mb={2}>
              Wenn du nicht weiterkommst, helfen dir Hinweise. Das Angucken
              jedes Hinweises{" "}
              <strong>
                kostet dich{" "}
                {formatDuration(hintPenaltyDuration, {
                  locale: locales[language],
                })}
              </strong>
              , die zum Schluss zu deiner Gesamtzeit hinzuaddiert werden. Aus
              diesem Grund guck dir die Hinweise bitte nur dann an, wenn du sie
              wirklich benötigst.
            </Text>
          )}
          <Text mb={8}>Viel Erfolg und viel Spaß!</Text>
        </>
      ) : null}

      <FormCode rNb={0} isResetForm={isResetForm} mb={8} />
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(PageStart)
