/* eslint-disable import/no-webpack-loader-syntax */
import React from "react"
import { connect } from "react-redux"
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react"
import { SETUP } from "../../const/setup"
import { MDXProvider } from "@mdx-js/react"
import components from "../../theme/components"
import { hintsUpdate } from "../../redux/actions/game"
import Hint01 from "!babel-loader!@mdx-js/loader!./Hint01.mdx"
import Hint02 from "!babel-loader!@mdx-js/loader!./Hint02.mdx"
import Hint03 from "!babel-loader!@mdx-js/loader!./Hint03.mdx"

const Hints = ({ rNb, game, dispatch }) => {
  //=================================================================
  const { hintLang } = SETUP
  const { hintsCount, language } = game

  const dispatchHintsUpdate = (hintArray) => {
    const payload = { rNb, hintArray }
    dispatch(hintsUpdate(payload))
  }

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Accordion
      allowMultiple
      allowToggle
      mt={8}
      mb={8}
      onChange={dispatchHintsUpdate}
    >
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {hintLang[language]} 01
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <MDXProvider components={components}>
            <Hint01 />
          </MDXProvider>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem isDisabled={hintsCount[rNb] === 0}>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {hintLang[language]} 02
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <MDXProvider components={components}>
            <Hint02 />
          </MDXProvider>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem isDisabled={hintsCount[rNb] <= 1}>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {hintLang[language]} 03
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <MDXProvider components={components}>
            <Hint03 />
          </MDXProvider>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(Hints)
