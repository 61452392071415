import React from "react"
import { connect } from "react-redux"
import { Container, Heading, Text, Image } from "@chakra-ui/react"
import formatDuration from "date-fns/formatDuration"
import {
  durationToSeconds,
  secondsToDuration,
} from "../../functions/utils/durationAndSeconds"
import iSuccess from "../../img/1280/16x9/success.jpg"
import { enUS as en, de } from "date-fns/locale"
import { SETUP } from "../../const/setup"

const PageSuccess = ({ game }) => {
  //=================================================================
  const locales = { en, de }

  const { hintPenaltyDuration } = SETUP

  const { durationSuccess, isTimeUp, hintsCount, language } = game

  const { duration, hasHints } = SETUP

  const durationSuccessInSec = durationToSeconds(durationSuccess)
  const hintsAddOnInSec = durationToSeconds(hintPenaltyDuration) * hintsCount[0]
  const durationTotal = secondsToDuration(
    durationSuccessInSec + hintsAddOnInSec
  )

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Container maxW="1200px" minH="640px">
      <Image src={iSuccess} mt={8} />
      {language === "en" ? (
        <>
          <Heading as="h1" textStyle="h1" mb={4}>
            Success
          </Heading>
          {isTimeUp ? (
            <>
              <Text fontWeight="bold">Well done you made it!</Text>
              <Text mb={2}>
                (Even though you didn't quite make it in time. But never mind.)
              </Text>
            </>
          ) : (
            <Text fontWeight="bold" mb={2}>
              Well done you made it!
            </Text>
          )}
          <Text mb={2}>
            You were given: <strong>{formatDuration(duration)}</strong>.
          </Text>
          <Text mb={2}>
            Your game time was:{" "}
            <strong>{formatDuration(durationSuccess)}</strong>.
          </Text>
          {hasHints && (
            <>
              <Text mb={2}>
                Overall you needed <strong>{hintsCount[0]} hints</strong>. Each
                hint counted for {formatDuration(hintPenaltyDuration)}.
              </Text>
              <Text mb={2}>
                Including hints, your total time therefore is:{" "}
                <strong>{formatDuration(durationTotal)}</strong>.
              </Text>
            </>
          )}
          <Text mb={2}>
            Glad you played along. If you enjoyed the Escape Room game, feel
            free to tell others.
          </Text>
        </>
      ) : language === "de" ? (
        <>
          <Heading as="h1" textStyle="h1" mb={4}>
            Erfolg
          </Heading>
          <Text fontWeight="bold" mb={2}>
            Herzlichen Glückwunsch. Ihr habt es geschafft!
          </Text>
          {isTimeUp && (
            <Text mb={2}>
              (Auch wenn ihr es nicht ganz in der Zeit geschafft habt. Macht
              aber nichts.)
            </Text>
          )}
          <Text mb={2}>
            Ihr hattet zur Verfügung:{" "}
            <strong>
              {formatDuration(duration, { locale: locales[language] })}
            </strong>
            .
          </Text>
          <Text mb={2}>
            Eure Spielzeit war:{" "}
            <strong>
              {formatDuration(durationSuccess, { locale: locales[language] })}
            </strong>
            .
          </Text>
          {hasHints && (
            <>
              <Text mb={2}>
                Insgesamt habt ihr <strong>{hintsCount[0]} Hinweis(e)</strong>{" "}
                angeguckt. Jeder Hinweis kostet dich{" "}
                {formatDuration(hintPenaltyDuration, {
                  locale: locales[language],
                })}
                .
              </Text>
              <Text mb={2}>
                Damit ist die Gesamtzeit:{" "}
                <strong>
                  {formatDuration(durationTotal, { locale: locales[language] })}
                </strong>
                .
              </Text>
            </>
          )}
          <Text mb={2}>
            Bitte macht jetzt einen Screenshot von eurer Spielzeit und sendet
            mir diesen in einer Email an:{" "}
            <a href="mailto://s.schoenborn@cbs.de">s.schoenborn@cbs.de</a>. Die
            schnellste Gruppe gewinnt und ist{" "}
            <strong>Digital-Escape-Room Champion 2022</strong> im Fach{" "}
            <em>Kommunikation & Gesprächsführung.</em>
          </Text>
          <Text mb={2}>
            Schön, dass ihr mitgespielt hast. Wenn euch das Escape-Room-Spiel
            Spaß gemacht hat, sagt es ruhig weiter.
          </Text>
        </>
      ) : null}
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(PageSuccess)
