
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Voluptate pariatur aliqua qui do dolor eiusmod magna cillum eu ullamco
reprehenderit aliqua minim laboris. Ea velit proident aliquip exercitation duis
laborum dolore anim sint. Sit occaecat ipsum ut tempor ea labore nostrud
incididunt voluptate irure sit officia aliquip. Sunt nulla incididunt
adipisicing dolore eu veniam fugiat enim ex. Veniam cupidatat dolore excepteur
et sit velit. Nostrud exercitation deserunt quis et cillum elit consequat culpa
commodo.`}</p>
    <p>{`Laboris commodo quis enim tempor magna est minim qui anim ea fugiat incididunt.
Aute id do fugiat minim occaecat fugiat qui. Esse minim laborum in culpa minim
adipisicing sunt sunt id magna velit laboris. Anim nisi exercitation qui nostrud
sit.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;