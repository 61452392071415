/* eslint-disable import/no-webpack-loader-syntax */
import React from "react"
import { connect } from "react-redux"
import { Container } from "@chakra-ui/react"
import { MDXProvider } from "@mdx-js/react"
import components from "../../theme/components"
import ContentEn from "!babel-loader!@mdx-js/loader!./Content.en.mdx"
import ContentDe from "!babel-loader!@mdx-js/loader!./Content.de.mdx"

const PageAbout = ({ game }) => {
  //=================================================================
  const { language } = game

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Container maxW="1200px" minH="640px">
      <MDXProvider components={components}>
        <MDXProvider components={components}>
          {language === "en" ? (
            <ContentEn />
          ) : language === "de" ? (
            <ContentDe />
          ) : null}
        </MDXProvider>
      </MDXProvider>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(PageAbout)
