
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i01 from "../../img/1280/16x9/01.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i01} mdxType="Image" />
    <h1>{`Willkommen im Burgfoyer!`}</h1>
    <p>{`Erinnert euch bitte an eure Vorlesung: `}<em parentName="p">{`Grundlagen der Kommunikation`}</em>{`.`}</p>
    <p>{`Wir haben unterschiedliche Kommunikationsformen kennengelernt.`}</p>
    <Text mb={2} mdxType="Text">
  Löst folgende Aufgabe:{" "}
  <Link href="https://learningapps.org/display?v=pbqsxfmq221" isExternal color="purple.300" _hover={{
        color: "purple.600"
      }} mdxType="Link">
    https://learningapps.org/display?v=pbqsxfmq221
  </Link>
    </Text>
    <p>{`Wie viele Aspekte findet ihr bei der nonverbalen Kommunikationsform? Gebt die
gefundene Zahl als Kennwort für den nächsten Raum ein (schreibt das Wort bitte
aus).`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;