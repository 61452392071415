
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i09 from "../../img/1280/16x9/09.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i09} mdxType="Image" />
    <h1>{`Die Schatzkammer`}</h1>
    <p>{`Ihr seid in der Schatzkammer angelangt! Findt nun noch den dreistelligen Code
für die Schatztruhe heraus.`}</p>
    <p>{`Lasst uns dazu über Rhetorik sprechen. Begründet in der Antike basiert sie auf
der sogenannten Fünfsatzmethode. Was hat es damit auf sich?`}</p>
    <p>{`In euren Kursmaterialien findet ihr einen Auszug aus einem Fachbuch zum Thema
Rhetorik ("Rhetorik im Job"). Öffnet die Datei im Ordner "Digital Escape Room"
und lest euch die Seiten durch.`}</p>
    <p>{`Löst das folgende Rätsel und erhaltet den Code zur Schatztruhe:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Wie viele Arten von Fünfsätzen unterscheidet man? Die Anzahl als Ziffer ist
die erste Ziffer des Kennwortes für den nächsten Raum. Notieren Sie sich
diese.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Betrachtet die grundlegende Struktur des Reihen-Fünfsatzes (A.) näher. Wie
viele Aspekte werden i.d.R. innerhalb einer Argumentation aufgezählt?
Verwendet die Ziffer an der zweiten Position des Kennwortes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Schaut euch die grundlegende Struktur des Standpunkt-Fünfsatzes (D.) genau an.
Im Text wird auf einen Autor verwiesen (Albert Thiele), den ihr bereits in der
Vorlesung Argumentations- und Diskussionstechniken kennengelernt habt. Werft
einen Blick in euer Handout "Argumentation Teil 1". Albert Thiele ist der
Begründer einer 4-stufigen Methode zur Abwehr persönlicher Angriffe. Jede der
vier Stufen beginnt mit demselben Buchstaben. Dieser Buchstabe steht an
dritter Stelle des Kennwortes.`}</p>
      </li>
    </ul>
    <p>{`Es ergibt sich jetzt ein dreistelliges, alphanumerisches Kennwort.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;