
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i04 from "../../img/1280/16x9/04.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i04} mdxType="Image" />
    <h1>{`Im Wohnzimmer`}</h1>
    <p>{`In der Anrichte des Wohnzimmers findet ihr einen Zettel mit dem nächsten Rätsel…`}</p>
    <p>{`Was macht `}<em parentName="p">{`Aktives Zuhören`}</em>{` aus? Auf dem Zettel ist noch lesbar:`}</p>
    <ol>
      <li parentName="ol">{`akustische …`}</li>
      <li parentName="ol">{`emotionale Beteiligung,`}</li>
      <li parentName="ol">{`Paraphrasieren.`}</li>
    </ol>
    <p>{`Zum `}<em parentName="p">{`Aktiven Zuhören`}</em>{` gehören drei Anteile. Lest ggf. nochmals in eurem Handout
"Techniken der Kommunikation" nach, welches der erste Anteil ist.`}</p>
    <p>{`Das gesuchte Wort ist das Kennwort für den nächsten Raum.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;