import { createBreakpoints } from "@chakra-ui/theme-tools"
const breakpoints = createBreakpoints({
  sm: "20.063em", // from "321px",
  md: "48em", // from "768px",
  lg: "60.063em", // from "961px",
  xl: "80.063em", // from "1281px",
  "2xl": "100em", // from "1600px",
})

// Internally, chakra-ui transforms this to
// const breakpoints = ["0em", "20.063em", "48em", "60.063em", "80.063em", "100em"]

// original
//                          0, 480px,  768px,  992px, 1280px,
// const breakpoints = ["0em", "30em", "48em", "62em", "80em"]

export default breakpoints
