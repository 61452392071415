/* eslint-disable import/no-webpack-loader-syntax */
import React from "react"
import { connect } from "react-redux"
import { Container, Heading } from "@chakra-ui/react"
import FormCode from "../../components/FormCode"
import { MDXProvider } from "@mdx-js/react"
import components from "../../theme/components"
import Hints from "./Hints"
import Riddle from "!babel-loader!@mdx-js/loader!./Riddle.mdx"
import { SETUP } from "../../const/setup"

const PageRiddle03 = ({ game }) => {
  //=================================================================
  const { riddleHeadings, hasHints } = SETUP
  const { isOnPause } = game
  const rNb = 3

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Container maxW="1200px" minH="640px">
      <Heading as="h1" textStyle="h1">
        {riddleHeadings[rNb]}
      </Heading>

      {!isOnPause && (
        <>
          <FormCode rNb={rNb} />

          <MDXProvider components={components}>
            <Riddle />
          </MDXProvider>

          {hasHints && <Hints rNb={rNb} />}
        </>
      )}
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(PageRiddle03)
