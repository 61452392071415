
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i03 from "../../img/1280/16x9/03.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const Text = makeShortcode("Text");
const Link = makeShortcode("Link");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i03} mdxType="Image" />
    <h1>{`TaDaa — das Musikzimmer`}</h1>
    <p>{`Zeit zum Ausruhen? Die habt ihr nicht!`}</p>
    <p>{`Denkt zurück an die Vorlesung: `}<em parentName="p">{`Techniken der Kommunikation`}</em>{`.`}</p>
    <p>{`Erinnert euch an das Konzept der `}<em parentName="p">{`Gewaltfreien Kommunikation`}</em>{`? Kernelement sind
die sogenannten Ich-Botschaften. Sie sollen Inhalte, insbesondere Bitten
vermitteln, ohne Widerstände beim Gegenüber hervorzurufen.`}</p>
    <Text mb={2} mdxType="Text">
  Löst bitte das folgende Rätsel:{" "}
  <Link href="https://learningapps.org/display?v=pauy666kc21" isExternal color="purple.300" _hover={{
        color: "purple.600"
      }} mdxType="Link">
    https://learningapps.org/display?v=pauy666kc21
  </Link>
    </Text>
    <p>{`Wenn ihr das Rätsel gelöst habt, erscheint das Kennwort für den nächsten Raum.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;