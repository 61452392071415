const styles = {
  global: () => ({
    "*": {
      boxSizing: "border-box",
      fontWeight: "medium",
      fontSize: "md",
      outline: "none",
    },
    body: {},
    a: {
      color: "purple.800",
      _hover: { color: "purple.100", textDecoration: "none" },
    },
    ".react-icons": {
      position: "relative",
      top: "5px",
    },
  }),
}

export default styles
