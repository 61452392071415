// https://redux-toolkit.js.org/usage/usage-guide

import { configureStore, combineReducers } from "@reduxjs/toolkit"
import logger from "redux-logger"

import preloadedState from "./states/initialState"
import game from "./reducers/game"

const reducers = combineReducers({
  game,
})

const loggerMiddleware = process.env.NODE_ENV !== "production" ? [logger] : []

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
  devTools: process.env.NODE_ENV !== "production",
  preloadedState,
  // enhancers,
})
