
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`License`}</h1>
    <p>{`This game is currently in test mode. Therefore, at the moment there is no
license fee.`}</p>
    <p>{`If you want to get a test license key, please write to: `}<a parentName="p" {...{
        "href": "mailto:prof.anders@online.de"
      }}>{`prof.anders@online.de`}</a></p>
    <p>{`You will then get a license key that will be valid for 30 days.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;