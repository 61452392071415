
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i07 from "../../img/1280/16x9/07.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i07} mdxType="Image" />
    <h1>{`Mondän — das Gästezimmer`}</h1>
    <p>{`Sie haben das Gästezimmer erreicht.`}</p>
    <p>{`Beantwortet die drei folgenden Fragen:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Wie heißt Kommunikation auf Englisch? (Verwendet den ersten Buchstaben als
erste Stelle des Codes).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ein gutes Argument setzt sich aus drei Teilen zusammen. Seht euch diese Teile
nochmal in eurem Handout "Argumentation Teil 1" an. Alle drei Komponenten
beginnen mit dem gleichen Buchstaben. Dieser Buchstabe ist die zweite Stelle
des Codes.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Das "Thomas-Kilmann-Conflict Mode Instrument (TKI)" unterscheidet fünf
verschiedene Konfliktbewältigungsstrategien. Eine Bewältigungsstrategie liegt
im "Vermeiden". Welches Tier repräsentiert diese Bewältigungsstrategie? Der
erste Buchstabe ist die dritte Stelle des Codes.`}</p>
      </li>
    </ol>
    <p>{`Es ergibt sich jetzt ein dreistelliger Buchstabencode für den nächsten Raum.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;