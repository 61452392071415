
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In nisi incididunt est excepteur sit commodo duis. Velit proident voluptate ad
quis tempor laborum voluptate voluptate. Ea ad deserunt enim occaecat. Nisi
consectetur non do esse incididunt mollit tempor Lorem. Sint veniam laboris
aliqua ullamco voluptate pariatur aute laboris nostrud laborum id aliqua
aliquip. Sit esse elit veniam occaecat Lorem aliqua aliqua sint est. Officia
consectetur eu sunt est deserunt et non labore laborum anim reprehenderit.`}</p>
    <p>{`Commodo officia laborum eu sit officia amet esse nisi. Exercitation esse magna
nulla magna velit. Do aute duis veniam commodo culpa reprehenderit ea sit sint.
Aliquip id laborum cupidatat incididunt ad irure sint Lorem magna. Sunt eiusmod
consectetur ex sunt velit esse excepteur nostrud non fugiat elit commodo. Minim
tempor aliqua exercitation officia ea sit elit ad adipisicing eu qui dolor
aliqua quis. Ut sit est laborum ex sit voluptate sit est id.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;