
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import i02 from "../../img/1280/16x9/02.jpg"

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Image = makeShortcode("Image");
const Link = makeShortcode("Link");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Image src={i02} mdxType="Image" />
    <h1>{`Zeit zum Nachdenken im Kaminzimmer…`}</h1>
    <p>{`Ein bedeutendes Kommunikationsmodell stammt von dem
Kommunikationswissenschaftler Friedemann Schulz von Thun. Es nennt sich
4-Ohren-Modell oder Kommunikationsquadrat. Welches sind die vier Ebenen, auf
denen wir Nachrichten senden und empfangen? Geht auf die folgende Seite und löst
das Rätsel. Dann kommt wieder zurück ins Kaminzimmer.`}</p>
    <Link href="https://learningapps.org/display?v=p6t60ivak21" isExternal color="purple.300" display="block" mb={2} _hover={{
      color: "purple.600"
    }} mdxType="Link">
  https://learningapps.org/display?v=p6t60ivak21
    </Link>
    <p>{`Was verbirgt sich hinter der roten Ebene? Der Begriff ist das Kennwort für den
nächsten Raum.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;