import { NUMBER_OF_RIDDLES } from "../../const/setup"

const gameInitialState = {
  game: {
    isInitialized: true,
    isResetForm: false,
    isDebugOn: false,
    isToBeLoginShown: false,
    toBeLoginName: "",
    toBeLoginCode: "",
    isOpenFeedback: false,
    isOpenTimeUp: false,
    isOnPause: false,
    isTimeUp: false,
    isSuccess: false,
    hasHints: false,
    durationSuccess: {},
    expiryTimestamp: null,
    riddlePageURLs: [],
    riddleHeadings: [
      "Erfolg",
      "01 Burgfoyer",
      "02 Kaminzimmer",
      "03 Musikzimmer",
      "04 Wohnzimmer",
      "05 Küche",
      "06 Badezimmer",
      "07 Gästezimmer",
      "08 Bibliothek",
      "09 Schatzkammer",
    ],
    codes: [
      "", // depends on loginName
      "vier",
      "Appell",
      "Rosenberg",
      "Signale",
      "Einwandbehandlung",
      "Tom",
      "CBS",
      "Dom",
      "93i",
    ],
    attemptsCount: Array(NUMBER_OF_RIDDLES + 1).fill(0),
    solved: Array(NUMBER_OF_RIDDLES + 1).fill(false),
    hintsCount: Array(NUMBER_OF_RIDDLES + 1).fill(0),
    language: "de",
  },
}

export default gameInitialState
