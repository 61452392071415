// https://www.gridlover.net/try
// Font Size: 16
// Line Height: 1.5
// Scale Factor: 1.414 aug. forth
// Line heights and margins not yet applied

// It seems that it is necessary to explicitly define Headings in theme
// otherwise textStyles do not override the default styles

const textStyles = {
  h1: {
    fontSize: ["32px", "45px"],
    color: "purple.800",
    mt: 8,
    mb: 4,
  },
  h2: {
    fontSize: ["25px", "32px"],
    color: "purple.800",
    mt: 4,
    mb: 2,
  },
  h3: {
    fontSize: ["20px", "25px"],
    color: "purple.800",
    mt: 2,
    mb: 2,
  },
  h4: {
    fontSize: ["16px", "20px"],
    color: "purple.800",
    mt: 2,
    mb: 2,
  },
  h5: {
    fontSize: ["16px"],
    color: "purple.800",
    mt: 2,
    mb: 2,
  },
  h6: {
    fontSize: ["16px"],
    color: "purple.800",
    mt: 2,
    mb: 2,
  },
}

export default textStyles
