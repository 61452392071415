
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Über`}</h1>
    <h2>{`Rätsel`}</h2>
    <p>{`Der Inhalt für dieses Spiel einschließlich der Rätsel, des Kontexts und der
Bildauswahl wurde von Susanne Schoenborn
`}<a parentName="p" {...{
        "href": "mailto://s.schoenborn@cbs.de"
      }}>{`s.schoenborn@cbs.de`}</a>{` erarbeitet.`}</p>
    <h2>{`Idee, Technik und Anpassungen`}</h2>
    <p>{`Idee & Technik stammen von Lilli Anders.`}</p>
    <p>{`Anpassungen & Technik wurden von Prof. Dr. Ulrich Anders vorgenommen. Sende
Anregungen und Anmerkungen gern an:
`}<a parentName="p" {...{
        "href": "mailto://prof.anders@online.de"
      }}>{`prof.anders@online.de`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;