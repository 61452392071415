/**
 * v1.0.0
 *
 * Pick a random element from an array
 * @param {array} arr
 * @returns picked
 */
export function randomPickFromArray(arr) {
  const index = Math.round(Math.random() * (arr.length - 1))
  const picked = arr[index]

  return picked
}
