import React from "react"
import { connect } from "react-redux"
import { Select } from "@chakra-ui/react"
import colors from "../../theme/colors"
import { languageUpdate } from "../../redux/actions/game"

const SelectLanguage = ({ game, dispatch }) => {
  //=================================================================

  const { language } = game

  const onChange = (e) => {
    let payload = { language: e.target.value }
    dispatch(languageUpdate(payload))
  }

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Select value={language} onChange={onChange} bg="grey.600" border="none">
      <option value="en" style={{ background: colors.grey[600] }}>
        EN
      </option>
      <option value="de" style={{ background: colors.grey[600] }}>
        DE
      </option>
    </Select>
  )
}

const mapStateToProps = (state) => {
  return {
    game: state.game,
  }
}

export default connect(mapStateToProps)(SelectLanguage)
